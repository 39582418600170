@import "../../variables.scss";

.menuBurger {
  cursor: pointer;
  margin-bottom: 3px;
  display: flex;
  background: #f9f9f9;
  width: 40px;
  height: 40px;
  border-radius: 0.75rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    background-color: #e9e9e9;
  }

  &--isMainMenuPage {
    margin: 12px 22px;

    @media (min-width: 48rem) {
      margin: 17px 36px;
    }
  }

  .burger-line {
    width: 21px;
    height: 2px;
    background-color: #202125;
    margin: 3px 0;
  }

  &--open {
    border: 1px solid #dddddd;
  }
}

.wrapper.nav {
  overflow: hidden;
  margin-left: -700px;
  transition: margin-left 0.5s;
}

.wrapper.nav.open {
  margin-left: 0px;
  height: 2000px;
}

.flex-column {
  position: absolute;
  z-index: 1001;
  width: 280px;
  height: 100%;
  background-color: white;

  @media (min-width: 48rem) {
    box-shadow: 10px 0 15px -5px #d3d3d3;
  }

  > a {
    color: #809fff;

    &:hover {
      color: #8868e5;
    }
  }
}

.menuElement {
  margin: 1px 18px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 520;
  font-size: 10pt;
  color: $primary-blue;
  cursor: pointer;
}

.closeMenu {
  z-index: 1002;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 16px 16px 0 0;

  &:hover {
    background-color: #e9e9e9;
    border-radius: 50%;
  }

  @media (min-width: 48rem) {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 24px 24px 8px 0;
  }
}

@media (min-width: 48rem) {
  .wrapper.nav {
    overflow: hidden;
    margin-left: -1025px;
    transition: margin-left 0.5s;
  }

  .menuElement {
    margin: 6px 24px;
    font-size: 20px;
  }

  .flex-column {
    width: 500px;
  }
}

.main-menu-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.mainMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 998;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.mainMenuOverlay--visible {
    opacity: 1;
  }

  @media (min-width: 48rem) {
    display: none;
  }
}

.mainMenuOverlay--visible {
  display: block;
  opacity: 1;

  @media (min-width: 48rem) {
    display: none;
  }
}
