.loading-spinner {
  border: 3.5px solid #f3f3f3;
  border-top: 3.5px solid #3498db;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
