.feed-comments {
  display: none;

  &__close-button-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% + 42px);
    z-index: 1;

    &__close-button {
      position: relative;
      right: -187px;
      background-color: #dc2f2f;
      color: #fff;
      border: none;
      border-radius: 0 0.5rem 0.5rem 0;
      padding: 8px 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.2;

      &:hover {
        opacity: 0.9;
      }

      &__text {
        margin-bottom: 0.875rem;
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
    }
  }

  @media (min-width: 768px) {
    &--show {
      display: flex;
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 350px;
    border-right: 1px solid #e9e9e9;
  }

  &__comments-list {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0.5rem;
    height: 100%;
    flex: 1;

    @media (min-width: 768px) {
      padding: 0 0.5rem 0.5rem 0.5rem;
    }

    &__controls {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      padding: 1rem 0.5rem 1rem 0.5rem;
      // border-bottom: 1px solid #e9e9e9;
      scrollbar-width: thin;
      /* "thin", "auto", or "none" */
      scrollbar-color: #aaa #f1f1f1;
      /* thumb & track colors respectively */

      &__title {
        font-family: "SilkaMedium";
        font-size: 16px;
        color: #202125;
        margin-right: 1.25rem;
      }

      &__refresh-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 50%;
        padding: 0.75rem;
        background-color: #f9f9f9;

        &:hover {
          background-color: #e9e9e9;
        }
      }
    }
  }

  &__comment {
    width: 100%;
    border-radius: 0.75rem;
    padding: 1rem 1.25rem 0.75rem 1.25rem;
    color: #fff;
    font-size: 12px;
    font-family: "Silka";
    margin-bottom: 0.5rem;
    min-height: 114px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    word-wrap: break-word; // Add this line
    overflow-wrap: break-word; // Add this line
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    // Default background color (optional, in case no modifier is applied)
    background-color: #6340c7;

    &--is-not-clickable {
      cursor: default;
    }

    @media (min-width: 768px) {
      padding: 1.125rem 1.25rem 0.625rem 1.25rem;
      min-height: 112px;
    }

    &__header-and-content-wrapper {
      margin-bottom: 0.75rem;
    }

    &__content {
      &--with-image {
        display: flex;
        justify-content: flex-end;
      }

      &--building-like-long-address {
        @media (min-width: 768px) {
          margin-top: 0.25rem;
        }
      }

      &__text {
        &--with-image {
          padding: 0.5rem 0.5rem 0 0.5rem;
          display: flex;
          justify-content: flex-start;
          width: 100%;
        }

        &--admin-comment-with-image {
          padding-top: 0.15rem;
        }

        >a {
          color: #fff;
          text-decoration: underline;

          &:hover {
            color: #fff;
            opacity: 0.8;
          }
        }

        &--no-text {
          display: none;
        }

        &--off-market-willingness {
          >span {
            margin-left: 0.5rem;
          }

          &--could-buy {
            display: flex;
          }

          &__could-buy-icon {

            // height: 51px;
            >img {
              position: relative;
              bottom: 4px;
              margin-left: 0.5rem;
            }
          }

          &__could-sell-icon {
            height: 33px;

            >img {
              position: relative;
              top: 20px;
              left: 3px;
            }
          }

          /*           @media (min-width: 768px) {
            display: block;
          } */
        }
      }

      &__image-wrapper {
        padding: 0.5rem 0.5rem 0 0.5rem;

        &--admin-comment-with-image {
          padding-top: 0.15rem;
        }

        >img {
          border-radius: 0.375rem;
          width: 110px;
          max-height: 150px;
          object-fit: contain; // Ensures the entire image is visible
          background-color: #f9f9f9; // Fills the empty space
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        }
      }

      &__property-details {
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 10px;
      }

      &__property-details__description {
        // font-style: italic;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        padding: 4px 10px;
        font-size: 13px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        color: #333;
        position: relative;
        overflow-wrap: anywhere;

        // Todo: Links should be with the same color as the text, even after being clicked or hovered
        a {
          color: #333;
          text-decoration: underline;

          &:hover {
            color: #333;
            opacity: 0.8;
          }
        }
        
        .feed-comments__comment__content__property-details__show-toggle {
          all: unset;
          background: none;
          border: none;
          margin-left: 5px;
          color: #333;
          font-weight: bold;
          cursor: pointer !important;
          font-size: 12px;
          text-decoration: underline;
          padding: 0;
          display: inline-block;
          position: relative;
          z-index: 10;
          
          &:hover {
            opacity: 0.8;
          }
        }
      }

      &__property-details__specs {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      &__property-details__spec {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        padding: 4px 10px;
        font-size: 13px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        color: #333;
      }

      &__property-details__spec-icon {
        margin-right: 4px;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__vote-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        &__upvote {
          all: unset;
          cursor: pointer;
          transform: rotate(180deg);

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }

        &__downvote {
          all: unset;
          cursor: pointer;

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }

        &__vote-count {
          font-size: 12px;
          font-family: "SilkaMedium";
          color: #fff;
        }
      }

      &__menu-wrapper {
        width: 100%;
        height: 0;
        display: flex;
        justify-content: flex-end;
      }

      &__menu {
        position: relative;
        right: 19px;
        bottom: 36px;
        width: fit-content;
        height: fit-content;
        padding: 0.75rem;
        background-color: #f9f9f9;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

        &__remove-comment-button {
          all: unset;
          background-color: #f9f9f9;
          border-radius: 0.5rem;
          cursor: pointer;

          &__text {
            font-size: 12px;
            font-family: "Silka";
            color: #202125;
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }

          &--disabled {
            opacity: 0.5;
          }
        }

        &__show-menu-button {
          all: unset;
          cursor: pointer;
        }
      }
    }

    &--jaakko-comment {
      background-color: #14b685 !important;
    }

    &--off-market-willingness {
      background-color: #f8c100 !important;
      padding-top: 1.25rem;
      min-height: 102px;
    }

    &--like-building {
      background-color: #ff77cb !important;
      padding-top: 1.25rem;
      min-height: 92px;
    }

    &__like-icon {
      position: relative;
      bottom: 5px;
      margin-right: 0.25rem;
    }

    &--1 {
      background-color: #8095ff;
    }

    &--2 {
      background-color: #018860;
    }

    &--3 {
      background-color: #6340c7;
    }

    &--4 {
      background-color: #ff8e8e;
    }

    &--5 {
      background-color: #3e55cd;
    }

    &--6 {
      background-color: #14b685;
    }

    &--7 {
      background-color: #051fa2;
    }

    &--8 {
      background-color: #ff5e5e;
    }

    /*     &--9 {
      background-color: #ff77cb;
    } */

    &--xx {
      background-color: #ff5e5e;
    }

    &__timestamp {
      white-space: nowrap;
      /*       width: 54px;
      display: flex;
      justify-content: flex-end; */
    }

    &__commenter-order-number-and-timestamp-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      &--admin-comment {
        margin-bottom: 0.3rem;
      }

      &--no-text {
        margin-bottom: 0.45rem;
      }

      &--could-sell {
        margin-bottom: 0.7rem;
      }

      &--building-like {
        margin-bottom: 0;
      }
    }

    &__commenter-order-number {
      font-size: 12px;

      &__location-icon {
        position: relative;
        bottom: 2px;
        margin-right: 0.25rem;
      }

      &__jaakko-comment {
        display: flex;
        align-items: flex-start;

        &__jaakko-head {
          margin-right: 0.75rem;
          position: relative;
          bottom: 6px;
        }
      }

      &__off-market-willingness-comment {
        display: flex;
        align-items: flex-start;

        &__could-sell-icon {
          margin-right: 0.5rem;
          height: 0;

          >svg {
            position: relative;
            bottom: 11px;
          }
        }

        &__could-buy-icon {
          margin-right: 0.5rem;
          height: 0;

          >svg {
            position: relative;
            bottom: 8px;
          }
        }

        span:first-of-type {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
    text-align: center;

    &__content {
      max-width: 300px;
      background-color: #f9f9f9;
      border-radius: 0.75rem;
      padding: 2rem;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    &__title {
      font-family: "SilkaMedium";
      font-size: 18px;
      color: #202125;
      margin-bottom: 1rem;
    }

    &__message {
      font-family: "Silka";
      font-size: 14px;
      color: #555;
      line-height: 1.5;
    }
  }
}

.feed-comments__loading-spinner-overlay {
  position: absolute;
  top: calc(10% + 68px);
  /* slightly up from the absolute center */
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 999;
  /* Ensure it's above the list */

  @media (min-width: 768px) {
    display: none;
  }
}

.feed-comments__loading-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  height: 80px;
}

// Remove the old Load More button styles since they're no longer needed
.feed-comments__load-more-wrapper,
.feed-comments__load-more-button {
  // Keep these styles for now in case we need to revert back
  display: none;
}

// For better mobile display
@media (max-width: 768px) {
  .feed-comments__comment__content__property-details__specs {
    gap: 6px;
  }
  
  .feed-comments__comment__content__property-details__spec {
    font-size: 12px;
    padding: 3px 8px;
  }
}