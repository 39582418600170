.comments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 60rem) {
    margin-left: 4.9375rem;
    width: 55.439375rem;
  }

  &__comments-list {
    width: 100%;
  }

  &__comment {
    width: 100%;
    border-radius: 0.75rem;
    padding: 1rem 1.25rem 0.75rem 1.25rem;
    color: #fff;
    font-size: 12px;
    font-family: "Silka";
    margin-bottom: 0.5rem;
    min-height: 106px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    word-wrap: break-word; // Add this line
    overflow-wrap: break-word; // Add this line
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // Default background color (optional, in case no modifier is applied)
    background-color: #6340c7;

    &__header-and-content-wrapper {
      margin-bottom: 0.75rem;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__vote-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        &__upvote {
          all: unset;
          cursor: pointer;
          transform: rotate(180deg);

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }

        &__downvote {
          all: unset;
          cursor: pointer;

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }

        &__vote-count {
          font-size: 12px;
          font-family: "SilkaMedium";
          color: #fff;
        }
      }

      &__menu-wrapper {
        width: 100%;
        height: 0;
        display: flex;
        justify-content: flex-end;
      }

      &__menu {
        position: relative;
        right: 19px;
        bottom: 34px;
        width: fit-content;
        height: fit-content;
        padding: 0.75rem;
        background-color: #f9f9f9;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

        &__remove-comment-button {
          all: unset;
          background-color: #f9f9f9;
          border-radius: 0.5rem;
          cursor: pointer;

          &__text {
            font-size: 12px;
            font-family: "Silka";
            color: #202125;
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }

          &--disabled {
            opacity: 0.5;
          }
        }

        &__show-menu-button {
          all: unset;
          cursor: pointer;
        }
      }
    }

    &--jaakko-comment {
      background-color: #14b685 !important;
    }

    // Background colors for commenterOrderNumber 1 to 10
    &--1 {
      background-color: #8095ff; // 1. comment color
    }

    &--2 {
      background-color: #3e55cd; // 2. comment color
    }

    &--3 {
      background-color: #051fa2; // 3. comment color
    }

    &--4 {
      background-color: #ff5e5e; // 4. comment color
    }

    &--5 {
      background-color: #ff77cb; // 5. comment color
    }

    &--6 {
      background-color: #ff8e8e; // 6. comment color
    }

    &--7 {
      background-color: #f7cf3e; // 7. comment color
    }

    &--8 {
      background-color: #f8c100; // 8. comment color
    }

    &--9 {
      background-color: #14b685; // 9. comment color
    }

    &--10 {
      background-color: #018860; // 10. comment color
    }

    // For commenterOrderNumber 11 and above
    &--11 {
      background-color: #6340c7; // 11. comment color
    }

    &:last-child {
      min-height: 146px;
    }

    &__commenter-order-number-and-timestamp-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__commenter-order-number {
      font-size: 0.84375rem;
      font-family: "SilkaMedium";
      margin-bottom: 0.15rem;

      &__jaakko-comment {
        display: flex;
        align-items: flex-start;

        &__jaakko-head {
          margin-right: 0.75rem;
          position: relative;
          bottom: 6px;
        }
      }
    }

    &__content {
      &__images {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        margin: 0.625rem 0 0rem 0;

        &--with-text {
          margin-bottom: 1rem;
        }

        &__image-wrapper {
          &__expand-button-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            //width: 100%;
            height: 0;
            // width: 0;
            position: relative;
            bottom: 44px;
            right: 8px;

            &__button {
              all: unset;
              background-color: rgba(0, 0, 0, 0.3);
              border: none;
              cursor: pointer;
              width: fit-content;
              height: fit-content;
              padding: 0.5rem;
              /*               width: 28px;
              height: 28px; */
              border-radius: 50%;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

              &:hover {
                background-color: rgba(0, 0, 0, 0.5);
              }
            }
          }

          img {
            cursor: pointer;
            width: 100%;
            max-height: 350px;
            object-fit: cover;
            border-radius: 0.375rem;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  &__empty-state {
    display: flex;
    justify-content: center;
    border-radius: 0.75rem;
    padding: 4.3rem 1.25rem 0 1.25rem;
    // background-color: #8095FF;
    // background-color: #3E55CD;
    // background-color: #051FA2;
    // background-color: #FF5E5E;
    // background-color: #DC2F2F;
    // background-color: #FF8E8E;
    // background-color: #F7CF3E;
    // background-color: #F8C100;

    // background-color: #14B685;
    // background-color: #018860;
    //background-color: #6340C7;
    background-color: #8868e5;

    width: 100%;
    height: 286px;

    &__text {
      color: #f9f9f9;
      text-align: center;

      span {
        display: block;
      }
    }
  }

  &__add-comment-button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: center;
    width: 0;
    height: 0;
    bottom: 38px;
    padding-bottom: 30px;

    &--loading-comments {
      display: none;
    }

    &--no-comments {
      bottom: 88px;
      padding-bottom: 0;
    }

    // flex-direction: column-reverse;

    @media screen and (min-width: 48rem) {
      flex-direction: row;
    }

    &__button {
      /* Existing styles */
      all: unset;
      font-family: sans-serif;
      display: flex;
      /* Use flex layout */
      justify-content: center;
      /* Center horizontally */
      align-items: center;
      /* Center vertically */
      min-width: 64px;
      height: 64px;
      color: #fff;
      border: 3px solid #fff;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

      &:hover {
        color: #efefef;
        border-color: #efefef;
      }

      &__plus-sign {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3.5rem;
      }
    }
  }

  &__send-or-return-comment-button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 0;
    height: 0;
    bottom: 74px;
    flex-direction: column-reverse;
    // flex: 2;

    @media screen and (min-width: 48rem) {
      flex-direction: row;
      align-items: flex-start;
    }

    &__button {
      all: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3.5rem;
      min-width: 64px;
      height: 64px;
      color: #fff;
      border: 3px solid #fff;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

      &:hover {
        color: #efefef;
        border-color: #efefef;
      }
    }
  }

  &__add-comment-form {
    display: flex;
    flex-direction: column;
    background-color: #8868e5;
    width: 100%;
    height: 336px;
    border-radius: 0.75rem;
    padding: 0 0.75rem;

    @media screen and (min-width: 48rem) {
      height: 286px;
    }

    &__buttons {
      &__photo-button {
        width: 0;
        height: 0;
        // flex: 1;
        position: relative;
        bottom: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 46px;

        @media screen and (min-width: 48rem) {
          flex: unset;
          /*           left: 220px;
          bottom: 78px; */
          // left: 257px;
          //left: 26px;
          bottom: 78px;
          right: 66px;
          display: block;
        }

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: rgba(0, 0, 0, 0.3);
          background-color: #f7cf3e;
          border: none;
          cursor: pointer;
          border-radius: 50%;
          min-width: 48px;
          height: 48px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

          &--show-photo-buttons {
            opacity: 0.9;
          }
        }
      }
    }

    &__photo-buttons-wrapper {
      display: none;
      width: 0;
      height: 0;
      position: relative;
      bottom: 86px;

      @media screen and (min-width: 48rem) {
        bottom: 68px;
      }

      &--show {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 3;
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        width: 150px;
        background-color: #018860;
        padding: 12px;
        border-radius: 0.75rem;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        // TODO: Animate in and out
        transition: opacity 0.2s ease-in-out;
        position: relative;
        left: 10px;

        @media screen and (min-width: 48rem) {
          left: unset;
          right: 46px;
          width: 150px;
        }
      }

      &__add-photo-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
        border-radius: 0.375rem;
        border: 1px solid #f9f9f9;
        padding: 12px;
        margin-bottom: 12px;
        color: #202125;

        @media screen and (min-width: 48rem) {
          margin-bottom: 0;
          cursor: pointer;

          &:hover {
            background-color: #fff;
          }
        }
      }

      &__take-photo-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-radius: 0.375rem;
        border: 1px solid #f9f9f9;
        padding: 12px;
        margin-bottom: 0;
        color: #fff;

        @media screen and (min-width: 48rem) {
          display: none;
        }
      }
    }

    &__textarea-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__textarea-wrapper__textarea {
      all: unset;
      width: 100%;
      height: 100%;
      overflow: auto;
      resize: vertical;
      background-color: #14b685;
      border-radius: 0.75rem;
      border: none;
      padding: 12px;
      color: #fff;
      font-size: 16px;
      font-family: "Silka";
      resize: none;

      &::placeholder {
        color: #f9f9f9;
      }

      /*       @media screen and (min-width: 48rem) {
        font-size: 12px;
      } */
    }

    &__send-button {
      min-width: 6.5rem;
      width: fit-content;
      height: 2.53125rem;
      padding: 0.75rem;
      border-radius: 0.375rem;
      background: #3e55cd;
      font-size: 0.8437rem;
      font-family: "SilkaMedium";
      letter-spacing: 0.05em;
      color: white;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 1.25rem;

      @media screen and (min-width: 48rem) {
        margin-bottom: 0;
      }

      &:hover:not(:disabled) {
        cursor: pointer;
        color: #efefef;
      }

      &:disabled {
        opacity: 0.8;
        cursor: default;
      }

      span {
        margin-right: 0.75rem;
      }

      &__icon {
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    &__back-button {
      min-width: 6.85rem;
      width: fit-content;
      height: 2.53125rem;
      padding: 0.75rem;
      border-radius: 0.375rem;
      background-color: rgba(0, 0, 0, 0.1);
      // background-color: transparent;
      border: none;
      // border: 1px dotted #f9f9f9;
      font-size: 0.8437rem;
      font-family: "SilkaMedium";
      letter-spacing: 0.05em;
      color: white;
      // border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-right: 0;

      @media screen and (min-width: 48rem) {
        margin-right: 1.25rem;
      }

      &:hover:not(:disabled) {
        cursor: pointer;
        color: #efefef;
      }

      &:disabled {
        opacity: 0.8;
        cursor: default;
      }

      span {
        margin-right: 0.75rem;
      }

      &__icon {
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    &__photo-preview {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      width: 100%;
      height: 0;
      z-index: 1;
      position: relative;

      @media screen and (min-width: 48rem) {
        // max-height: 1000px;
      }

      > img {
        width: 100%;
        margin-bottom: 26px;
        /*         width: 100px;
        height: 100px;  */
        object-fit: cover;
        border-radius: 0.375rem;

        @media screen and (min-width: 48rem) {
          max-height: 600px;
          max-width: 1000px;
          object-fit: contain;
        }
      }

      &__remove-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0;
        position: relative;
        bottom: 196px;
        z-index: 1;

        > button {
          border-radius: 2rem;
          background: #f9f9f9;
          background-color: #ff5e5e;
          font-size: 0.8437rem;
          font-family: "SilkaMedium";
          letter-spacing: 0.05em;
          color: #202125;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-bottom: 1.25rem;
          border-radius: 50%;
          width: 48px;
          height: 48px;

          @media screen and (min-width: 48rem) {
            margin-bottom: 0;
          }

          &:hover:not(:disabled) {
            cursor: pointer;
            color: #efefef;
          }

          &:disabled {
            opacity: 0.8;
            cursor: default;
          }
        }
      }
    }
  }
}

/* Add this at the end of your existing CSS file */
body.ios .comments__add-comment-button-wrapper__button__plus-sign {
  position: relative;
  bottom: 6px;
}

@supports (-webkit-overflow-scrolling: touch) {
  .comments__add-comment-button-wrapper__button__plus-sign {
    position: relative;
    bottom: 6px;
  }
}

@media screen and (max-width: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .comments__add-comment-button-wrapper__button__plus-sign {
    position: relative;
    bottom: 6px;
  }
}
