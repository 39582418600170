.search-input {
  z-index: 102;
  position: absolute;
  top: 98px;
  left: 50%;
  width: 280px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  &--english {
    width: 296px;

    @media (min-width: 48rem) {
      width: 280px;
    }
  }

  @media (min-width: 768px) {
    top: 112px;
    left: calc(50% + 175px);

    &--feed-hidden {
      left: 50%;
    }
  }

  @media (min-width: 1032px) {
    left: 50%;
  }
}

.search-input input {
  width: 100%;
  height: 42px;
  padding-left: 1.1rem;
  border: 0.05rem solid rgba(55, 55, 55, 0.05);
  border-radius: 5rem;
  font-size: 16px;
  color: black;
  box-shadow: 0 0 2.5rem 0.2rem rgba(128, 128, 128, 0.4);
  -webkit-appearance: none;

  @media (min-width: 48rem) {
    //font-weight: 600;
    font-size: 0.8rem;
  }
}

.search-input input::placeholder {
  color: #6a6a6a;
}

.search-input > .form-row :after {
  content: "";
  position: absolute;
  top: 13px;
  right: 20px;
  display: block;
  height: 16px;
  /*height of icon */
  width: 16px;
  /*width of icon */
  /*where to replace the icon */
  background: #f000 url("./magnifyier_blue.png");
  background-size: 16px 16px;
}

.autocomplete-dropdown-container {
  line-height: 2;
  background-color: white !important;
  font-size: 11pt;
}

.autocomplete-dropdown-container:first-child {
  margin-top: 20px;
}

.autocomplete-dropdown-container .suggestion-item:first-child {
  margin-top: 15px;
}

.autocomplete-dropdown-container .suggestion-item {
  padding-left: 15px;
  background-color: white !important;
}
