.street-view-container {
  width: 100%;
  min-height: 400px;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 60rem) {
    width: 55.439375rem;
    margin-left: 4.9375rem;
  }

  .street-view-panorama {
    width: 100%;
    height: 100%;
  }

  .street-view-loading,
  .street-view-error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    z-index: 1;
  }

  .street-view-error {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    
    p {
      font-family: "Silka";
      color: #666;
    }
  }
} 

