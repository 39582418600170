// status of house
$housing-company-own: #dfd6c6;
$claimed-notforsell-notact: #00b8df;
$actively-sell: #86f4ca;
$not-for-sale: #f587a7;
$not-clamed: #f0f4f5;
$claimed-no-status: #96a8ac;

// primary colors
$primary-blue: #00b8df;
$primary-dark-blue: #2b4860;
$primary-bright-blue: #0a3f6a;
$primary-gray: #888;

$blur-gray: #ede9e6;
$bg-bt-light-gray: #f0f4f5;
$bg-light-gray: rgba(240, 244, 245, 0.5);
$bg-white: #ffffff;
$bg-orange: #fdf7ea;
$border-orange: #f2b325;
$bg-green: #e3faf1;

// height
$mobile-height: 170pt;
$tablet-height: 270pt;
$tablet-big-height: 290pt;
$desktop-height: 230pt;

$very-light-gray: #f9f9f9;

@font-face {
  font-family: "Silka";
  src: url("./fonts/Silka-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SilkaBold";
  src: url("./fonts/Silka-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SilkaSemiBold";
  src: url("./fonts/Silka-SemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SilkaMedium";
  src: url("./fonts/Silka-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
