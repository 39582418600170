@import "../../variables.scss";

.building-modal-global__submit-button-wrapper__text {
  width: 100%;
  font-size: 0.75rem !important;
  margin-bottom: 0.75rem;

  @media screen and (min-width: 60rem) {
    margin-bottom: 0;
  }
}

.building-modal-global__submit-button-wrapper__text__link {
  font-size: 0.75rem !important;
  color: #8095ff;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #3e55cd;
  }
}

.building-modal-global__jaakko-talking__text__first {
  display: block;
  margin-bottom: 0.5rem;
}

.building-modal-global__jaakko-talking__text {
  display: block;
}

.building-modal-global__jaakko-talking__link {
  color: #8095ff;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #3e55cd;
  }
}

a {
  outline: none;
}

.jaakko-talking-loading-dots {
  display: inline-block;
  font-size: 3.5rem;
  letter-spacing: 0.25em;
  position: relative;
  bottom: 30px;
  left: 3px;

  @media screen and (min-width: 60rem) {
    font-size: 4rem;
    letter-spacing: 3em;
  }
}

.jaakko-talking-loading-dots::before,
.jaakko-talking-loading-dots::after {
  content: ".";
  animation: jaakko-talking-blink 1.4s infinite both;
  position: absolute;
}

.jaakko-talking-loading-dots::before {
  left: 0;
  animation-delay: 0s;
}

.jaakko-talking-loading-dots::after {
  left: 0.2em;
  animation-delay: 0.35s;
}

.jaakko-talking-loading-dots span::after {
  content: ".";
  position: absolute;
  left: 0.4em;
  animation: jaakko-talking-blink 1.4s infinite both;
  animation-delay: 0.7s;
}

@keyframes jaakko-talking-blink {

  0%,
  20% {
    color: transparent;
  }

  40% {
    color: #202125;
  }

  100% {
    color: transparent;
  }
}

.building-modal-global__controls {
  color: #202125;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0.9375rem 0 0.9375rem;
  margin-bottom: 1.25rem;
  align-items: flex-end;

  @media screen and (min-width: 60rem) {
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }

  &__notifications {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 1.25rem;
  }

  &__share-button {
    all: unset; // Remove all default button styles
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.25rem;

    &__icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.05rem;
      margin-bottom: 0.4rem;
    }

    &__share-text {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.05rem;
      text-decoration: underline; // Add this line to underline the text
      cursor: pointer;
    }
  }

  &__like-button {
    all: unset; // Remove all default button styles
    display: flex;
    flex-direction: column;
    align-items: center;

    &:disabled {
      cursor: not-allowed;
    }

    &__icon-and-count {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.05rem;
      margin-bottom: 0.4rem;

      &__loading-spinner {
        position: relative;
        bottom: 2px;
        right: 3px;
        width: 15px !important;
        height: 15px !important;

        @media screen and (min-width: 48rem) {
          bottom: 1px;
          right: 4px;

          width: 16px !important;
          height: 16px !important;
        }
      }

      &__like-count {
        width: 0;
        position: relative;
        top: 0.2rem;
        right: 1.4rem;
        color: #fff;
      }
    }

    &__like-text {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.05rem;
      text-decoration: underline; // Add this line to underline the text
    }
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;

    &--disabled {
      cursor: not-allowed;
    }

    input {
      opacity: 0;
      /* Hide the checkbox */
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: background-color 0.3s ease;
      border-radius: 15px;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 3px;
        background-color: #fff;
        transition: transform 0.3s ease;
        border-radius: 50%;
      }
    }

    input:checked+.slider {
      background-color: #3e55cd;
    }

    input:checked+.slider:before {
      transform: translateX(24px);
    }

    &:hover .slider {
      background-color: #b3b3b3;
    }
  }
}

.building-modal-global__jaakko-text-wrapper {
  @media screen and (min-width: 60rem) {
    padding-left: 0.75rem;
  }
}

.building-modal-global__property-wrapper {
  width: 100%;
}

.building-modal-global__jaakko-talking {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.05rem;
}

.building-modal-global__jaakko-wrapper {
  display: flex;
  width: 100%;
  padding: 0.9375rem;
  margin-bottom: 0.75rem;
}

.building-modal-global-container {
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto; // This enables the scrollbar on the side of the screen
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 5% 0; // Adjust as needed
  z-index: 1001;

  @media (min-width: 48rem) {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); 
    z-index: 1200;
  }

  @media (min-width: 60rem) {
    top: 76px;
    z-index: 102;
  }
}

.building-modal-global__horizontal-line {
  width: 100%;
  height: 0;
  border-top: 1px solid #efefef;
  margin: 0 0 0.9375rem 0;

  @media screen and (min-width: 60rem) {
    &--off-market {
      display: none;
    }

    &--controls {
      display: none;
    }
  }

  @media screen and (min-width: 60rem) {
    width: 54.689375rem;
    margin-left: 4.9375rem;
  }
}

.building-modal-global__vertical-line {
  width: 0;
  flex: 0 1;
  border-left: 1px solid #efefef;
  margin: 0;
}

.building-modal-global__dropzone-toggle {
  height: 2rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  background: #14b685;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  min-width: 198.5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add this line for a subtle shadow

  @media screen and (min-width: 60rem) {
    margin-left: 5.875rem;
  }
}

.building-modal-global__dropzone-toggle__chevron {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  font-size: 0.75rem;
  margin-left: 0.75rem;
  justify-self: flex-end;
}

.building-modal-global__dropzone-toggle__chevron--up {
  transform: rotate(90deg);
}

/* Base styles */
.building-modal-global__dropzone-content {
  overflow: hidden;
  border: 0.09375rem dotted #ccc;
  border-radius: 0.75rem;
  padding: 0.9375rem;
  background-color: #f9f9f9;
  margin-bottom: 1.25rem;

  @media screen and (min-width: 60rem) {
    width: 54.689375rem;
    margin-left: 5.875rem;
  }
}

/* CSSTransition classes */
.dropzone-enter {
  max-height: 0;
}

.dropzone-enter-active {
  max-height: 1000px;
  transition: max-height 600ms ease-out;
}

.dropzone-exit {
  max-height: 1000px;
}

.dropzone-exit-active {
  max-height: 0;
  transition: max-height 600ms ease-in;
}

.building-modal-global__property {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.9375rem;
  margin-bottom: 0.5rem;

  &--global {
    margin-bottom: 0;
    margin-top: 1rem;

    @media screen and (min-width: 60rem) {
      margin-bottom: 0.25rem;
    }
  }

  @media screen and (min-width: 60rem) {
    flex-direction: row;
    margin-left: 5.6875rem;
    width: 55.439375rem;
  }

  &__building-and-general-info-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1.35rem;

    &--global {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: 60rem) {
      margin-bottom: 0.25rem;
      padding: 0 0.82rem;
    }
  }

  &__building {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-right: 1rem;

    svg {
      position: relative;
      top: 4px;
    }
  }

  &__general-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1.125rem 0.75rem 0;

    &__title {
      font-size: 0.8rem;
      font-family: "SilkaMedium";
      line-height: 1.05rem;
    }

    &__name {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.975rem;
    }

    &__address {
      font-size: 0.75rem;
    }
  }

  &__additional-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.75rem;

    &__title {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.05rem;
    }

    &__name {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.975rem;
    }
  }

  &__plot-ownership-and-redemption-clause-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 1px;

    @media screen and (min-width: 60rem) {
      flex: 1;
      padding: 0 0.82rem;
      position: relative;
      bottom: 0.1171875rem;
      margin-bottom: 0.75rem;
    }
  }

  &__plot-ownership {
    margin-right: 0.28125rem;

    svg {
      position: relative;
      top: 4px;
    }
  }

  &__redemption-clause {
    margin-right: 0.28125rem;

    svg {
      position: relative;
      top: 3px;
    }
  }

  &__plot-ownership-wrapper {
    flex: 3;
    display: flex;
    flex-direction: row;
    margin-right: 1rem;

    @media screen and (min-width: 60rem) {
      margin-right: 0;
    }
  }

  &__redemption-clause-wrapper {
    flex: 7;
    display: flex;
    flex-direction: row;
  }
}

.building-modal-global__dropzone-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.building-modal-global__dropzone {
  border: 0.09375rem dotted #ccc;
  border-radius: 0.75rem;
  padding: 0.9375rem;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  margin-bottom: 0.85rem;

  &--disabled {
    cursor: not-allowed;
  }

  &__input {
    &--disabled {
      cursor: not-allowed;
    }
  }

  img {
    margin-bottom: 0.28125rem;
  }
}

.building-modal-global__dropzone:hover {
  background-color: #e9e9e9;
}

.building-modal-global__dropzone--dragging {
  background-color: #d9d9d9;
  border-color: #999;
}

.building-modal-global__dropzone__text {
  font-size: 0.75rem;
  color: #666;
}

.building-modal-global__dropzone__clickable-text {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;

  &--disabled {
    cursor: not-allowed;
  }
}

.building-modal-global {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 64rem;
  z-index: 1001;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #202125;
  overflow-x: hidden;
  font-family: "Silka", sans-serif;
  padding: 0.8rem 0.9375rem 0.9375rem 0.9375rem;
  
  @media screen and (min-width: 60rem) {
    scrollbar-width: thin;
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.75rem;
    height: calc(100vh - 76px);
  }

  &__login-reminder {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.125rem;
    padding: 0.9375rem;

    &__text {
      &__link {
        font-family: "SilkaMedium";
        cursor: pointer;
        color: #3e55cd;
        text-decoration: underline;
      }
    }
  }

  &__submit-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 60rem) {
      justify-content: flex-end;
      flex-direction: row;
    }

    &__loading {
      display: flex;
      align-items: center;
      width: 315px;
      font-size: 0.75rem;
      color: #202125;
      border: 0.09375rem dotted #666;
      border-radius: 0.75rem;
      padding: 0.75rem;

      @media screen and (min-width: 60rem) {
        width: 445px;
      }
    }

    &__loading-spinner {
      all: unset;
      cursor: unset !important;
      margin-right: 0.75rem;
      background: #3e55cd;
      border-radius: 1.5rem;
      padding: 0.75rem;
      width: fit-content;
      height: fit-content;
    }

    &__loading-text {
      color: #666;
    }

    &__submit-button {
      min-width: 5.8125rem;
      height: 2.53125rem;
      padding: 0.75rem;
      border-radius: 0.375rem;
      background: #3e55cd;
      font-size: 0.8437rem;
      font-family: "SilkaMedium";
      letter-spacing: 0.05em;
      color: #ffff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add this line for a subtle shadow

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 0.75rem;
    margin-right: 0.5rem;

    @media screen and (min-width: 48rem) {
      margin-right: 1rem;
    }

    @media screen and (min-width: 60rem) {
      margin-bottom: 1.25rem;
    }

    &__close-button {
      all: unset;
      cursor: pointer;
      border-radius: 50%;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      &:hover {
        background-color: #e9e9e9;
      }

      @media (min-width: 48rem) {
        &:focus {
          background-color: #e9e9e9;
        }
      }
    }
  }

  &__jaakko-head {
    margin-right: 0.75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    /*     width: 100%;
    max-width: 80rem; */
    background-color: $very-light-gray;
    border-radius: 0.75rem;
    padding: 0.75rem;
    margin-bottom: 0.9375rem;
    width: 100%;

    @media screen and (min-width: 60rem) {
      margin-left: 4.9375rem;
      width: 55.439375rem;
    }

    &__title {
      width: 100%;
      font-family: "SilkaSemiBold";
      font-size: 0.9375rem;
      margin-bottom: 0.5rem;
    }

    &__section__expander {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.25rem;

      @media screen and (min-width: 60rem) {
        margin-left: 4.9375rem;
        width: 55.439375rem;
        padding-right: 0.75rem;
      }

      &__button {
        all: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-family: "SilkaBold";
        font-weight: 700;
        font-size: 1.5rem;

        @media screen and (min-width: 353px) {
          font-size: 1.828125rem;
        }

        &__arrow {
          transition: transform 0.3s ease-in-out;
        }

        &__arrow--up {
          transform: rotate(180deg);
        }
      }
    }

    &__sub-title {
      display: flex;
      justify-content: center;
      font-size: 0.84375rem;
      font-family: "SilkaMedium";
      margin-bottom: 0.75rem;

      &--files-drop-box {
        font-family: "Silka";
        text-align: center;
      }

      @media screen and (min-width: 60rem) {
        justify-content: flex-start;
        padding-left: 0.75rem;
      }
    }
  }

  &__quantitative_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;

    @media screen and (min-width: 60rem) {
      flex-direction: row;
      margin-bottom: 0.375rem;
      width: 55.439375rem;
      margin-left: 4.9375rem;
      padding: 0.75rem;
    }

    &__sub-title {
      display: flex;
      font-family: "SilkaMedium";
      font-size: 1.25rem;
      margin-bottom: 0.75rem;

      @media screen and (min-width: 60rem) {
        // font-size: 0.84375rem;
        justify-content: flex-start;
        padding-left: 0.82rem;
      }
    }

    &__apartments-info-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0.75rem;
      border-radius: 0.75rem;
      font-weight: 400;
      font-size: 0.75rem;

      @media screen and (min-width: 60rem) {
        padding: 0.75rem;
      }
    }

    &__apartment-counts-and-off-market-buttons {
      @media screen and (min-width: 60rem) {
        flex: 1;
        padding: 0 1.32rem 0 0;
      }
    }
  }

  &__apartment-counts-wrapper {
    flex-direction: column;
    flex: 1;
    margin-bottom: 1.5rem;

    @media screen and (min-width: 60rem) {
      margin-bottom: 1.75rem;
    }
  }

  &__owned-apartments-and-commercial-spaces-wrapper {
    width: 100%;

    @media screen and (min-width: 60rem) {
      width: unset;
      margin-bottom: 1rem;
    }
  }

  &__apartments-and-commercial-spaces-owned-by-the-housing-company {
    flex: 1;

    &__title {
      display: flex;
      font-family: "SilkaMedium";
      margin-bottom: 0.75rem;
      font-size: 1.25rem;

      @media screen and (min-width: 60rem) {
        justify-content: flex-start;
        padding: 0 0 0 0.82rem;
        //font-size: 0.84375rem;
      }
    }

    &__apartments-and-commercial-spaces {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 60rem) {
        padding: 0 0 0 0.82rem;
      }
    }

    &__apartments {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.75rem;
      background-color: #f9f9f9;
      border-radius: 0.75rem;
      padding: 0.75rem;

      svg {
        margin-right: 0.75rem;
        width: 52px;
      }

      &__count {
        display: flex;
        flex-direction: column;
        margin-right: 0.75rem;

        span {
          &:first-child {
            font-size: 1.171875rem;
            font-family: "SilkaBold";
            display: flex;
            justify-content: center;
            width: 100%;

            &--blurred {
              opacity: 0.5;
            }
          }

          &:nth-child(2) {
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.5625rem;
          }
        }
      }

      &__text {
        font-size: 0.75rem;
      }

      @media screen and (min-width: 60rem) {
        padding-right: 0;
      }
    }

    &__commercial-spaces {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f9f9f9;
      border-radius: 0.75rem;
      padding: 0.75rem;

      svg {
        margin-right: 0.75rem;
        width: 52px;
      }

      &__count {
        display: flex;
        flex-direction: column;
        margin-right: 0.75rem;

        span {
          &:first-child {
            font-size: 1.171875rem;
            font-family: "SilkaBold";
            display: flex;
            justify-content: center;
            width: 100%;
          }

          &:nth-child(2) {
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.5625rem;
          }
        }
      }

      &__text {
        font-size: 0.75rem;
      }

      @media screen and (min-width: 60rem) {
        padding-right: 0;
      }
    }

    @media screen and (min-width: 60rem) {
      padding: 0 0 0 0.82rem;
    }
  }

  &__info-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.75rem;
    background-color: #fff;
    border-radius: 0.75rem;
    font-weight: 400;
    font-size: 0.75rem;

    &__rectangle {
      margin-right: 0.75rem;
      min-width: 0.46875rem;
      gap: 0px;
      border-radius: 0.46875rem;

      &--yellow {
        background: #ffd84e;
      }

      &--green {
        background: #14b685;
      }

      &--red {
        background: #ff5e5e;
      }
    }
  }
}

.apartment-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
}

.apartment-indicator {
  width: 0.753125rem;
  height: 0.753125rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.375rem;

  &--1h {
    background-color: #3e55cd;
  }

  &--2h {
    background-color: #8095ff;
  }

  &--3h {
    background-color: #ff8e8e;
  }

  &--4h {
    background-color: #ff5e5e;
  }
}

.apartment-distribution-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-bottom: 0.25rem;
  padding: 0 0.25rem;

  @media screen and (min-width: 60rem) {
    /*     height: 153px;
    align-items: center; */
    padding: 0 0.82rem;
  }
}

.apartment-distribution {
  display: grid;
  grid-template-columns: repeat(10, 0.75rem); // 0.65625rem * 1.3333
  grid-template-rows: repeat(10, 0.75rem); // 0.65625rem * 1.3333
  width: 7.5rem; // 6.5625rem * 1.3333
  height: 7.5rem; // 6.5625rem * 1.3333
  gap: 0;

  @media screen and (min-width: 60rem) {
    padding: 0.375rem 0; // 0.28125rem * 1.3333
  }

  &--blurred {
    pointer-events: none;
  }

  &__square {
    width: 0.75rem; // 0.65625rem * 1.3333
    height: 0.75rem; // 0.65625rem * 1.3333

    &--1h {
      background-color: #3e55cd;
    }

    &--2h {
      background-color: #8095ff;
    }

    &--3h {
      background-color: #ff8e8e;
    }

    &--4h {
      background-color: #ff5e5e;
    }

    &--top-left {
      border-top-left-radius: 0.7292rem;
    }

    &--top-right {
      border-top-right-radius: 0.7292rem;
    }

    &--bottom-left {
      border-bottom-left-radius: 0.7292rem;
    }

    &--bottom-right {
      border-bottom-right-radius: 0.7292rem;
    }
  }
}

.comments__comment__content a {
  color: #f9f9f9;
  text-decoration: underline;
}

.comments__comment__content a:hover {
  color: #fff;
}

.building-modal-global__off-market-buy-and-sell {
  margin-bottom: 1.5rem;

  &--global {
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;

    @media screen and (min-width: 60rem) {
      margin-top: 1rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    &__button {
      background-color: #f9f9f9;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 0.75rem 0;
      border-radius: 2rem;
      font-size: 0.75rem;
      font-family: "Silka";
      letter-spacing: 0.05em;
      color: #202125;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus {
        outline: none;
      }

      >svg {
        margin-right: 0.5rem;
        transition: all 0.25s ease-out;
        stroke: transition-stroke 0.5s ease-out;
        fill: transition-fill 0.5s ease-out;
      }

      &--selected {
        box-shadow: 0 0 0 2px #8095ff;
        border: 1px solid #8095ff;
        background-color: #f9f9f9;
        transition: all 0.25s ease-out;
      }

      &:first-child {
        margin-right: 0.75rem;
      }

      &:last-child {
        margin-left: 0.75rem;
      }

      @media screen and (min-width: 60rem) {
        // min-width: 215px;
        // flex-direction: row;
        min-width: 195px;
        width: fit-content;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &--selected {
          box-shadow: 0 0 0 2px #8095ff;
          border: 1px solid #8095ff;
          background-color: #f9f9f9;
          transition: all 0.25s ease-out;

          &:hover {
            box-shadow: 0 0 0 2px #8095ff;
          }
        }
      }

      &__text-and-count {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 60rem) {
          flex-direction: row;
        }
      }

      &__text {
        @media screen and (min-width: 60rem) {
          margin-right: 0.4rem;
        }
      }

      &__count {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 60rem) {
          min-width: 2.5rem;
        }

        &__spinner {
          position: relative;
          top: 2px;
          height: 1rem !important;
          width: 1rem !important;

          @media screen and (min-width: 60rem) {
            top: 1px;
          }
        }
      }
    }
  }

  &__info-button {
    all: unset;
    font-size: 0.75rem;
    background-color: #f7cf3e;
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;
    width: fit-content;
    margin: 1.25rem auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    color: #fff;

    >svg {
      margin-left: 0.4rem;
    }

    @media screen and (min-width: 60rem) {
      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__info-modal-wrapper {
    height: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__info-modal {
    position: relative;
    top: 8px;
    display: flex;
    align-items: flex-start;
    width: 310px;
    height: fit-content;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    border-radius: 1.25rem;
    padding: 1rem;
    font-size: 0.875rem;
    border: 6px solid #f9f9f9;
    outline: #f9f9f9;
    transition: all 1s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      top: 100%; // Position it at the bottom of the div
      left: 50%; // Center it horizontally
      transform: translateX(-50%); // Adjust for centering
      border-width: 16px 16px 0 16px; // Adjust the border to point downwards
      border-style: solid;
      border-color: #f9f9f9 transparent transparent transparent; // Adjust the color to point downwards

      @media screen and (min-width: 48rem) {
        left: 50%; // Ensure it remains centered on larger screens
        transform: translateX(-50%); // Adjust for centering
      }
    }

    @media screen and (min-width: 60rem) {
      bottom: 38px;
    }
  }
}

.building-modal-global__off-market-toast-component {
  position: fixed;
  bottom: -100px;
  /* start off-screen */
  /*   left: 50%;
  transform: translateX(-50%); */
  background-color: #232323;
  opacity: 0.9;
  color: #fff;
  padding: 16px 24px;
  font-size: 0.8rem;
  font-family: "SilkaMedium";

  letter-spacing: 0.05em;
  width: 100%;

  transition: bottom 0.5s ease;
  z-index: 9999;
  /* ensure it appears on top */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 48rem) {
    width: 800px;
    border-radius: 0.375rem;
  }

  &__close-button {
    all: unset;
    cursor: pointer;
  }
}

.building-modal-global__off-market-toast-component--slide-in {
  bottom: 0;

  /* slide into view */
  @media screen and (min-width: 48rem) {
    bottom: 24px;
  }
}