.navbar-signup-login-wrapper {
  cursor: pointer;
  @media (min-width: 48rem) {
    &:hover {
      border-radius: 12px;
      background-color: #f9f9f9;
    }
  }

  @media (min-width: 48rem) {
    height: 52px;
    padding: 0 8px;
  }
}

.navbar-signup-login {
  display: flex;
  flex-direction: column;
  z-index: 101;
  cursor: pointer;
  font-size: 0.875rem;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  min-width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;

  @media (min-width: 48rem) {
    margin-right: 0;
    min-width: 60px;
    height: 46px;
    order: 3;
  }

  &__text {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    /* Ensures text is centered on both lines */
    font-size: 10px;
    
    @media (min-width: 48rem) {
      font-size: 0.875rem;
    }

    &--logged-in {
      font-size: x-small;
      display: block;
      /* Forces text onto separate lines on mobile */

      @media (min-width: 48rem) {
        display: inline;
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      >span {
        /* Direct child selector for added specificity */
        display: block;
        /* Forces each span onto a new line on mobile */

        @media (min-width: 48rem) {
          display: inline;
          /* Allows spans to be inline on larger screens */
          font-size: initial;
        }
      }
    }
  }
}