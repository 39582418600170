.map-container {
  height: 100%;
  width: 100%;

  /*   @media (min-width: 48rem) {
    padding-top: 76px;
    height: calc((var(--vh, 1vh) * 100) - 76px);
  } */
}

.map-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  //height: calc(var(--vh, 1vh) * 100 - 68px);
  height: 100%;

  @media (min-width: 768px) {
    // height: calc(var(--vh, 1vh) * 100 - 76px);

    &--feed-open {
      width: calc(100% - 350px);
    }
  }
}

.click-jaakko-hint {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 87px;
  transform: translateX(-50%);
  left: 50%;
  width: fit-content;
  background: rgba(249, 249, 249, 0.8);
  padding: 12px 14px;
  border-radius: 0.75rem;
  font-size: 14px;
  color: #000;
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;

  > img {
    margin-left: 8px;
  }

  @media screen and (min-width: 48rem) {
    transform: inherit;
    left: inherit;
    right: 110px;
    padding: 24px;
    bottom: 38px;
    width: fit-content;
    display: flex;
  }
}

.zoom-in-hint {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 149px;
  transform: translateX(-50%);
  left: 50%;
  width: fit-content;
  white-space: nowrap;
  background: rgba(249, 249, 249, 0.8);
  padding: 12px 14px;
  border-radius: 0.75rem;
  font-size: 14px;
  color: #000;
  z-index: 1;
  cursor: pointer;

  > img {
    margin-left: 8px;
  }

  @media screen and (min-width: 48rem) {
    transform: inherit;
    left: inherit;
    right: 110px;
    padding: 24px;
    bottom: 126px;
    display: flex;
  }
}

.click-building-hint {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 211px;
  transform: translateX(-50%);
  left: 50%;
  width: fit-content;
  background: rgba(249, 249, 249, 0.8);
  padding: 12px 14px;
  border-radius: 0.75rem;
  font-size: 14px;
  color: #000;
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;

  > img {
    margin-left: 8px;
  }

  @media screen and (min-width: 48rem) {
    transform: inherit;
    left: inherit;
    right: 110px;
    padding: 24px;
    bottom: 216px;
    width: fit-content;
  }
}

.jaakko-speech-bubble {
  position: absolute;
  bottom: 56px;
  transform: inherit;
  right: 82px;
  width: 220px;
  height: 420px;
  /*   background-color: white;
 */
  background-color: #f9f9f9;
  padding: 24px 16px;
  border-radius: 0.75rem;
  font-size: 14px;
  color: #202125;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 20px;
    right: -8px;
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent #f9f9f9;

    @media screen and (min-width: 48rem) {
      right: -10px;
    }
  }

  > a {
    color: #8095ff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #3e55cd;
    }
  }

  > img {
    cursor: pointer;
    margin-left: 8px;
  }

  @media screen and (max-width: 375px) {
    width: 220px;
    height: 422px;
  }

  @media screen and (min-width: 48rem) {
    transform: inherit;
    left: inherit;
    right: 106px;
    padding: 24px;
    bottom: 38px;
    width: 288px;
    height: 570px;

    &::after {
      bottom: 20px;
      right: -10px;
    }
  }
}

.jaakko-info {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 56px;
  right: 14px;
  z-index: 1;
  cursor: pointer;
  /* Ensure the hint appears above other elements */

  @media screen and (min-width: 48rem) {
    bottom: 38px;
  }
}

.map-feed-vertical-button {
  display: none;

  &:hover {
    opacity: 0.9;
  }

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #dc2f2f;
    color: #fff;
    border: none;
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 8px 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.2;
    z-index: 1;

    &__text {
      margin-bottom: 0.875rem;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}

@media (max-width: 768px) {
  .custom-navigation-control {
    display: none;
  }
}
