@import '../../variables.scss';

.messages-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.messages-modal {
  background-color: white;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  height: 80vh;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
  padding-top: 68px;

  @media (min-width: 48rem) {
    padding-top: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    border-bottom: 1px solid #ebebeb;
    position: relative;

    &-left {
      all: unset;
      cursor: pointer;
      padding: 4px 8px;
      position: absolute;
      // left: 4px;

      &:hover {
        background-color: #f7f7f7;
        border-radius: 25%;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      flex-grow: 1;
      text-align: center;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    position: absolute;
    right: 56px;

    &-icon {
      background-color: #f7f7f7;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        background-color: #ebebeb;
      }
    }
  }

  &__filter {
    background-color: #f7f7f7;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #ebebeb;
    }
  }

  &__close {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    position: absolute;
    right: 20px;
    top: 13px;

    @media (min-width: 48rem) {
      top: 14px;
    }

    &:hover {
      background-color: #e9e9e9;
      border-radius: 50%;
    }
  }

  &__tabs {
    display: flex;
    border-bottom: 1px solid #ebebeb;
    padding: 0 24px;
  }

  &__tab {
    background: none;
    border: none;
    color: #717171;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0;
    margin-right: 24px;
    cursor: pointer;
    position: relative;

    &--active {
      color: #000;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
      }
    }

    &:hover:not(&--active) {
      color: #000;
    }
  }

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0;
  }

  &__conversations {
    display: flex;
    flex-direction: column;
  }

  &__conversation {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f7f7f7;
    }

    &--unread {
      background-color: rgba(0, 106, 255, 0.05);

      .messages-modal__conversation-preview {
        font-weight: 600;
      }
    }
    
    &--ended {
      opacity: 0.8;
      
      .messages-modal__conversation-preview {
        color: #717171;
      }
    }

    &-avatar {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &-ended-tag {
      color: #717171;
      font-style: italic;
      font-size: 13px;
    }

    &-content {
      flex-grow: 1;
      min-width: 0;

      &-bottom {
        display: flex;
        flex-direction: row;

        &-left {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-width: 0;
        }
      }

      &-icon-open {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-time {
      font-size: 14px;
      color: #717171;
      white-space: nowrap;
      margin-left: 8px;
    }

    &-building {
      font-size: 14px;
      color: #717171;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-preview {
      font-size: 14px;
      color: #222;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #717171;
    font-size: 16px;
    padding: 32px 28px;

    @media (min-width: 48rem) {
      padding: 32px 46px;
    }
  }

  &__header-menu-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  &__header-menu {
    position: absolute;
    top: 100%;
    left: 46px;
    margin-top: -21px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    // border: 1px solid #e9e9e9;
    z-index: 10;
    min-width: 180px;
    overflow: hidden;
/*     animation: menuFadeIn 0.1s ease-out;
 */  }
  
  &__header-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #202125;
    
    &:hover {
      background-color: #f7f7f7;
      background-color: #fef2f2;
    }
  }
}

@media (max-width: 768px) {
  .messages-modal {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }

  .messages-modal-background {
    background-color: white;
  }
}

.conversation {
  &__header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #dddddd;
    min-height: 64px;
    width: 100%;
  }

  &__back {
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__user {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1;
  }

  &__user-avatar {
    flex-shrink: 0;
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }

  &__user-info {
    min-width: 0;
    flex: 1;

    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #222222;
    }

    p {
      font-size: 14px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #717171;
    }
  }
}

.end-chat-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; // Higher than the messages modal
  padding: 20px;
}

.end-chat-confirmation {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #ebebeb;
    
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }
  
  &__close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    &:hover {
      background-color: #f7f7f7;
    }
  }
  
  &__content {
    padding: 24px;
    
    p {
      font-size: 15px;
      line-height: 1.5;
      margin: 0;
      color: #222;
    }
  }
  
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #ebebeb;
  }
  
  &__button {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &--cancel {
      background: none;
      border: none;
      color: #222;
      margin-right: 12px;
      
      &:hover:not(:disabled) {
        background-color: #f7f7f7;
      }
    }
    
    &--confirm {
      background-color: #db574f;
      border: none;
      color: white;
      
      &:hover:not(:disabled) {
        background-color: #c94c41;
      }
      
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  
  &__loading-dots {
    position: relative;
    
    &::after {
      content: '...';
      position: absolute;
      animation: loading-dots 1.5s infinite;
    }
  }
}

@keyframes loading-dots {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}