@import "../../variables.scss";

.signup-login-modal__content__text__link {
  color: #8095ff;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #3e55cd;
  }
}

.signup-login-modal__content__button--login {
  margin-bottom: 0.5rem !important;
}

.signup-login-modal__content__text {
  font-size: 0.75rem !important;
  margin-bottom: 24px;
  display: block !important;

  @media screen and (min-width: 768px) {
    margin-bottom: 12px;
  }
}

a {
  outline: none;
}

.loading-dots {
  display: inline-block;
  font-size: 3rem;
  letter-spacing: 0.2em;
  position: relative;
  bottom: 49px;
}

.loading-dots::before,
.loading-dots::after {
  content: ".";
  animation: blink 1.4s infinite both;
  position: absolute;
}

.loading-dots::before {
  left: 0;
  animation-delay: 0.35s;
}

.loading-dots::after {
  left: 0.2em;
  animation-delay: 0.7s;
}

.loading-dots span::after {
  content: ".";
  position: absolute;
  left: 0.4em;
  animation: blink 1.4s infinite both;
  animation-delay: 1.4s;
}

@keyframes blink {
  0%,
  20% {
    color: transparent;
  }

  40% {
    color: black;
  }

  100% {
    color: transparent;
  }
}

.signup-login-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  background-color: #fff;
  color: #202125;
  overflow-x: hidden;
  font-family: "Silka", sans-serif;
  padding: 0.8rem;
  z-index: 1001;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #efefef;

  &__info {
    display: none;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    overflow-y: auto;
    background-color: #fff;
    color: #202125;
    overflow-x: hidden;
    font-family: "Silka", sans-serif;
    padding: 1.25rem;
    z-index: 1001;
    right: 440px;
    height: 580px;
    min-height: 520px;
    width: 340px;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0.75rem;
    border: 1px solid #efefef;
    box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 768px) {
      display: flex;
      position: fixed;
      right: 60%;
    }

    @media screen and (min-width: 805px) {
      right: 60%;
    }

    @media screen and (min-width: 1260px) {
      // position: relative;
      // right: 400px;
      position: relative;
      right: 400px;
    }

    &__title {
      font-size: 1.25rem;
      font-family: "SilkaSemiBold";
      padding: 0.75rem;
      display: flex;
      // align-items: center;
      padding: 0.75rem 0.75rem 0 0.75rem;
      margin: 1.1rem 0 0.9rem 0;

      > img {
        position: relative;
        bottom: 11px;
        right: 10px;
        margin-right: 0rem;
      }
    }

    &__texts-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__rectangle-and-text-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0.75rem;
      background-color: #fff;
      border-radius: 0.75rem;
      /*       box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 12px; */

      &__rectangle {
        margin-right: 0.75rem;
        min-width: 0.46875rem;
        border-radius: 0.46875rem;
        background-color: #8095ff;
        height: 100%;
      }

      &__text {
        font-size: 14px;
        // color: #fff;
        /*       background-color: #efefef;
        border-radius: 0.75rem; */
      }
    }

    &__image-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      padding: 0.75rem;
      border-radius: 0.75rem;
      width: 100%;
      height: 100%;
    }

    /*     height: 100%;
    width: 100%; */
  }

  &__info-mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    color: #202125;
    font-family: "Silka", sans-serif;
    padding: 1.25rem;
    z-index: 1001;
    right: 440px;
    height: 580px;
    min-height: 564px;
    max-width: 340px;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0.75rem;
    border: 1px solid #efefef;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    // margin-bottom: 0.5rem;
    margin-top: 0.75rem;

    @media screen and (min-width: 768px) {
      display: none;
    }
    &__title {
      font-size: 1.25rem;
      font-family: "SilkaSemiBold";
      padding: 0.75rem;
      display: flex;
      // align-items: center;
      padding: 1.5rem 0.75rem 0.5rem 0.75rem;
      margin: 0 0 0 0;
      width: 100%;
      justify-content: center;

      > img {
        position: relative;
        bottom: 11px;
        right: 10px;
        margin-right: 0rem;
      }
    }

    &__texts-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    &__rectangle-and-text-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0.75rem;
      padding: 0.75rem 0;
      background-color: #fff;
      border-radius: 0.75rem;
      /*       box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 12px; */

      &__rectangle {
        margin-right: 0.75rem;
        min-width: 0.46875rem;
        border-radius: 0.46875rem;
        background-color: #8095ff;
        height: 100%;
      }

      &__text {
        font-size: 14px;
        /*         color: #018860;
        color: #f8c100;
        color: #3e55cd; */
        color: #565656;
        // color: #fff;
        /*       background-color: #efefef;
        border-radius: 0.75rem; */
      }
    }

    &__image-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      padding: 0.75rem;
      border-radius: 0.75rem;
      width: 100%;
      height: 100%;
    }

    /*     height: 100%;
    width: 100%; */
  }

  @media screen and (min-width: 768px) {
    border-radius: 0.75rem;
    width: auto;
    width: 500px;
    height: auto;
    max-height: calc(100vh - 2rem);
    border-radius: 0.75rem;
    // height: 300px;
    margin: auto;
    position: fixed;
    left: 35%;
    z-index: 1001;
    /*     margin-left: -250px;
    margin-top: -150px; */

    height: auto;
    max-height: calc(100vh - 2rem); // optional safeguard
    overflow-y: auto; // enable scrolling if it exceeds viewport
    margin: auto;

    &--signup {
      min-height: 360px;
    }

    &--email-verification {
      min-height: 361px;
    }

    &--logout-view {
      height: 230px;
      min-height: 230px;
    }
  }

  @media screen and (min-width: 805px) {
    left: 38%;
  }

  @media screen and (min-width: 1260px) {
    left: unset;
  }

  &--no-info-box-view {
    left: unset;
  }

  &__submit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1.125rem;

    &__submit-button {
      min-width: 5.8125rem;
      height: 2.53125rem;
      padding: 0.75rem;
      border-radius: 0.375rem;
      background: #3e55cd;
      font-size: 0.84375rem;
      letter-spacing: 0.05em;
      color: #ffff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      height: 54px;
    }

    &__close-button {
      all: unset;
      cursor: pointer;
      border-radius: 50%;
      padding: 0.5rem;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      &:hover {
        background-color: #e9e9e9;
      }

      @media (min-width: 48rem) {
        &:focus {
          background-color: #e9e9e9;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
    background-color: $very-light-gray;
    border-radius: 0.75rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    height: 54px;

    &__or {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 16px 0;
      padding: 0;
      font-size: 0.75rem;
      white-space: nowrap;

      > hr {
        border-color: #2021251f;
        width: 100%;
      }

      > hr:first-of-type {
        margin: 0 16px 0 0;
      }

      > hr:last-of-type {
        margin: 0 0 0 16px;
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      font-family: "SilkaSemiBold";
      font-size: 1rem;
    }

    &__button {
      min-width: 100%;
      height: 54px;
      padding: 0.75rem;
      border-radius: 0.375rem;
      background: #ff5e5e;
      font-size: 0.8437rem;
      font-size: 14px;
      letter-spacing: 0.05em;
      font-family: "SilkaSemiBold";
      color: #ffff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25rem;

      &:disabled {
        opacity: 0.5;
      }

      &:hover {
        color: #fefefe;
        opacity: 0.9;
      }
    }

    &__form {
      width: 100%;
    }

    &__input {
      width: 100%;
      height: 54px;
      border-radius: 0.375rem;
      padding-left: 16px;
      margin-bottom: 12px;
      border: 1px solid #b7b7b7;
    }

    &__error {
      color: red;
      font-size: 0.84375rem;
      margin-bottom: 0.75rem;
      padding: 0 0.75rem;
    }

    &__subtitle {
      font-size: 0.84375rem;
      margin-bottom: 0.75rem;
      padding: 0 0.75rem;
    }
  }
}

.signup-login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1201;
}

.custom-google-signin-button {
  background-color: #fff;
  color: #202125;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
  min-height: 54px;
  border: 1px solid #b7b7b7;
  font-family: "SilkaSemiBold";
  font-size: 14px;

  &__icon {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text {
    flex: 2;
  }

  &__filler {
    flex: 1;
  }
}

.custom-google-signin-button:hover {
  background-color: #f9f9f9;
}

// Style for the Forgot Password button in Login view
.signup-login-modal__forgot-password-button {
  all: unset; // Reset default button styles
  cursor: pointer;
  color: #8095ff;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem; // Space above
  width: 100%; // Make it full width for easier clicking
  padding: 0.25rem;

  &:hover {
    color: #3e55cd;
  }
}

// Style for the Back to Login button in Forgot Password view
.signup-login-modal__back-button {
  all: unset;
  cursor: pointer;
  color: #565656; // A neutral color
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.75rem; // Space above
  width: 100%;
  padding: 0.25rem;
  display:none;
  
  &:hover {
    text-decoration: underline;
    color: #202125;
  }
}

// Style for the general message area (used for success/info)
.signup-login-modal__content__message {
  color: #018860; // Green color for success/info
  font-size: 0.84375rem;
  margin-bottom: 0.75rem;
  padding: 0 0.75rem;
  text-align: center;
}
