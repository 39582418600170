// Basic styling for the Reset Password Page
@import '../../variables.scss'; // Import variables if needed

body.reset-password-page-wrapper {
  background: linear-gradient(to bottom, #EFEFEF, #FFFFFF);
  min-height: 100vh; // Ensure gradient covers full height
}

.reset-password-page {
  max-width: 90%;
  margin: 4rem auto; // Center the box with some top margin
  padding: 2rem;
  border: 1px solid #efefef;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  font-family: 'Silka', sans-serif; // Use the app's font
  text-align: center;

  @media (min-width: 768px) {
    max-width: 450px;
  }

  &__logo {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-family: 'SilkaSemiBold', sans-serif;
    color: #202125;
  }

  &--success {
    border-color: #28a745; // Use a standard green hex code for now
  }

  &__message {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    line-height: 1.4;

    &--error {
      color: red;
    }

    &--success {
      color: #28a745; // Use a standard green hex code for now
      font-weight: bold;
    }
  }

  &__form {
    text-align: left;
  }

  &__form-group {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 0.9rem;
      color: #565656;
    }

    input[type='password'] {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ccc;
      border-radius: 0.375rem;
      font-size: 1rem;

      &.input-error {
          border-color: red;
      }
    }
  }

  &__error-text {
      color: red;
      font-size: 0.8rem;
      margin-top: 0.25rem;
  }

  &__button {
      display: inline-block;
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 0.375rem;
      background-color: #ff5e5e; // Match modal button color
      color: white;
      font-size: 14px;
      font-family: 'SilkaSemiBold', sans-serif;
      cursor: pointer;
      transition: background-color 0.2s ease;
      margin-top: 1rem; // Add some space above button
      height: 54px;

      &:hover:not(:disabled) {
          background-color: darken(#ff5e5e, 10%);
      }

      &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
      }
  }

   &__link-button {
      all: unset; // Reset styles
      display: inline-block;
      margin-top: 1rem;
      color: #8095ff;
      font-size: 0.9rem;
      cursor: pointer;
      // text-decoration: underline;

       &:hover {
           color: #3e55cd;
       }
   }
} 