.loading-existing-report-skeleton {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;

  &__property {
    flex: 1;
    margin-bottom: 30px;

    &__header {
      height: 20px;
      width: 150px;
      background-color: #e0e0e0;
      margin-bottom: 10px;
      border-radius: 4px;
      height: 100%;
      animation: loadingAnimation 1.5s infinite;
    }
  }

  &__content {
    flex: 3;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    &__sub-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      flex: 1;
    }
  }
}

.loading-existing-report-skeleton__line {
  width: 100%;
  background-color: #e0e0e0;
  margin-bottom: 5px;
  border-radius: 4px;
  height: 100%;
  max-height: 100%;
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
