.navbar-language-selector {
    display: none;
    cursor: pointer;
    @media (min-width: 768px) {
        position: relative;
        display: inline-block;
        padding: 0 8px;
    }

    @media (min-width: 768px) {
        &:hover {
            border-radius: 12px;
            background-color: #f9f9f9;
        }
    }
}

.navbar-language-selector__button {
    all: unset;
    display: none;
    margin-top: 6px;
    font-size: 0.875rem;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    height: 46px;
    min-width: 60px;
    order: 2;

    @media (min-width: 768px) {
        display: flex;

        >svg {
            width: 20px;
            height: 20px;
        }
    }

    >span {
        text-transform: uppercase;
    }
}

.navbar-language-selector__dropdown {
    position: absolute;
    top: 115%;
    right: 16px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar-language-selector__dropdown-item {
    text-align: center;
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
        background-color: #f0f0f0;
    }

    &--active {
        font-weight: 600;
    }
}