.policy-page-container {
  height: 100vh;
  max-width: fit-content !important;
  min-width: 100vw !important;
  overflow-y: auto !important;
  display: block;
  padding-bottom: 60px;
}

.policy-page-content {
  padding: 1.25rem;
  max-width: 1140px;
  margin: 0 auto; // Center the content horizontally
  padding-bottom: 60px;
}

.closeMenuView {
  z-index: 1002;
  position: fixed;
  top: 7px;
  right: 16px;
  background: url(./close_dark.png) no-repeat center;
  background-size: 15px;
  height: 16px;
  width: 16px;
  padding: 20pt;
  cursor: pointer;

  @media (min-width: 48rem) {
    // padding: 2.5rem 2.7rem 2.5rem 2.5rem;
    background-size: 18px;
    height: 18px;
    width: 18px;
    top: 12px;
    right: 36px;
  }
}