@import "../../variables.scss";

.off-market-modal-overlay {
  position: fixed;
  top: -68px;
  padding-top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1201;

  @media (min-width: 60rem) {
    z-index: 1100;
    top: 76px;
    padding-bottom: 76px;
  }
}

.off-market-modal {
  background-color: white;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 16px 24px 24px 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  color: #202125;

  &__sell-icon {
    position: relative;
    right: 10px;
    bottom: 4px;

    @media (min-width: 48rem) {
      right: 12px;
      bottom: 4px;
      width: 27px;
      height: 27px;
    }
  }

  &__buy-icon {
    position: relative;
    right: 11px;
    bottom: 2px;

    @media (min-width: 48rem) {
      bottom: 1px;
      right: 12px;
      width: 28px;
      height: 28px;
    }
  }

  @media (min-width: 48rem) {
    padding: 16px 24px 24px 24px;
  }
  
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 8px; 
    background-color: #f9f9f9;
    height: 52px;
  }
  
  &__title {
    font-size: 1rem;
    margin: 0;
    color: #202125;
    font-family: "SilkaBold";

    @media (min-width: 48rem) {
      font-size: 19px;
    }
  }
  
  &__close-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 7px;
  }

  &__close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    
    &:hover {
      color: #333;
      background-color: darken(#f5f5f5, 5%);
    }
    
    &:disabled {
      color: #ccc;
      cursor: not-allowed;
    }
  }
  
  &__content {
    margin-bottom: 24px;
  }
  
  &__description {
    margin-bottom: 20px;
    color: #202125;
    line-height: 1.5;
  }
  
  &__form {
    padding-top: 16px;
    
    &-title {
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 16px 0;
      color: #202125;
    }
    
    &-field {
      margin-bottom: 16px;
      
      label {
        display: block;
        margin-bottom: 6px;
        font-size: 14px;
        color: #202125;
      }
      
      &--half {
        flex: 1;
        min-width: 0;
        
        &:first-child {
          margin-right: 12px;
        }
      }
      
      &--checkbox {
        display: flex;
        align-items: center;
        margin-right: 20px;
        
        input {
          margin-right: 8px;
        }
        
        label {
          margin-bottom: 0;
          cursor: pointer;
        }
      }
    }
    
    &-row {
      display: flex;
      margin-bottom: 16px;
      
      &--checkboxes {
        display: flex;
        margin-top: 8px;
      }
    }
  }
  
  &__expander {
    margin: 16px 0 32px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
    
    &-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: none;
      border: none;
      padding: 4px 4px 4px 0;
      font-size: 16px;
      font-weight: 600;
      color: #202125;
      cursor: pointer;
      text-align: left;
      
      &:hover {
        color: #333;
      }
      
      svg {
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease;
        
        &.report__content__section__expander__button__arrow--up {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  &__additional-details {
    padding-top: 8px;
  }
  
  &__input,
  &__select,
  &__textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #4f88ff;
    }
  }

  &__input {
    font-size: 16px;

    &::placeholder {
      font-size: 14px;
    }

    @media (min-width: 48rem) {
      font-size: 14px;
    }
  }

  &__select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    appearance: none; /* Removes default browser styling */
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 6px center; /* Position 12px from the right - adjust as needed */
    background-size: 16px; /* Size of the custom arrow */
    padding-right: 26px; /* Ensure text doesn't overlap with the arrow */
    
    &:focus {
      outline: none;
      border-color: #4f88ff;
    }
  }
  
  &__textarea {
    resize: vertical;
    font-size: 16px;

    &::placeholder {
      font-size: 14px;
    }
  }

  &__textarea-counter {
    font-size: 12px;
    color: #666;
  }
  
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  &__button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    border: none;
    
    &--cancel {
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
      color: #202125;
      
      &:hover {
        background-color: #e9e9e9;
      }
      
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    
    &--confirm {
      background-color: #8095FF;
      color: white;
      
      &:hover {
        background-color: darken(#8095FF, 5%);
      }
      
      &:disabled {
        background-color: lighten(#8095FF, 15%);
        cursor: not-allowed;
      }
    }
  }
  
  &__button-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 0.8s linear infinite;
  }
}

// Animation classes for details expansion
.details-expand-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.details-expand-enter-active {
  max-height: 500px;
  opacity: 1;
  transition: max-height 300ms ease-in-out, opacity 200ms ease-in;
}

.details-expand-exit {
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
}

.details-expand-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms ease-in-out, opacity 100ms ease-out;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .off-market-modal {
    width: 95%;
    padding: 16px 24px 24px 24px;
    
    &__form-row {
      flex-direction: column;
      
      .off-market-modal__form-field--half {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
} 