@import "./variables.scss";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,600,700&display=swap&subset=latin-ext");

html,
body {
  height: 100vh;
  overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif !important;
  overflow: hidden;
}

.flatsonBlueText {
  font-weight: bold;
  color: #809fff;
}

.App-Content {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 100%;
}

.App-wrapper {
  width: 100%;
  height: 100vh;

  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-0 > .container {
  padding: 0 !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.map-row {
  height: 500px; // Match the fixed height of the map container
}
