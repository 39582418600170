@import "../../../variables.scss";

.name {
  position: absolute;
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: $primary-bright-blue;
}

.flatsonBlueText {
  text-align: center;
  padding: 16px 16px 0px 16px;
  font-weight: bold;
  font-size: 14pt;
  color: #8095ff;
}

.page-name {
  margin: 10pt auto;
  font-size: 16pt;
  font-weight: 600;
  color: #051fa2;
  padding: 0 16px;
  text-align: center;
}

.p-0 a:hover {
  text-decoration: none;
}

.p-0 .col a {
  color: #8095ff;
}

.small-text {
  font-size: 10pt;
  color: #202125;
}

.title-center {
  font-size: 10pt;
  color: $primary-dark-blue;
  text-align: center;
}

.no-margin-text {
  margin: 0pt;
  font-size: 10pt;
  color: #2b4860;
}

.paragraph-title {
  color: #8095ff;
}

.list-item {
  margin: 10pt 0pt;
}
