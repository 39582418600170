@import "../../../variables.scss";

.image-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  background-color: #fff;
  color: #202125;
  overflow-x: hidden;
  font-family: "Silka", sans-serif;
  z-index: 1202;
  height: 100%;
  width: 100%;
  margin-top: 136px;

  @media screen and (min-width: 48rem) {
    margin-top: initial;
    border-radius: 0.75rem;
    width: fit-content;
    height: 100%;
    max-height: fit-content;
    /*     max-width: 1000px;
    max-height: 1000px; */
    border-radius: 0.75rem;
    // height: 300px;
    margin: auto;
    position: fixed;
    padding: 1.25rem;
    overflow-y: auto !important; // enable scrolling if it exceeds viewport
    //margin: auto;
    //margin-top: 152px;
  }

  &__image-wrapper {
    position: relative;
    /* to position the zoom button absolutely */

    @media screen and (min-width: 48rem) {
      img {
        object-fit: cover;
        max-width: 1000px;
        max-height: 1000px;
      }
    }
  }

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 56px;
    margin-bottom: 12px;
    padding: 0.8rem 0.8rem 0 0.8rem;

    @media screen and (min-width: 48rem) {
      height: 22px;
      padding: 0;

      > button {
        position: relative;
        bottom: 10px;
        left: 10px;
      }
    }
  }
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1201;
}
