@import '../../variables.scss';

.conversation {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #ebebeb;
  }

  &__back {
    background: none;
    border: none;
    padding: 8px;
    margin-right: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f7f7f7;
      border-radius: 50%;
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-info {
      h3 {
        margin: 0 0 4px;
        font-size: 16px;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #717171;
      }
    }
  }

  &__messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 24px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-spinner {
      width: 24px;
      height: 24px;
      border: 3px solid #f3f3f3;
      border-top: 3px solid #3e55cd;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__message-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__date-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    span {
      background: #f7f7f7;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 12px;
      color: #717171;
    }
  }

  &__message {
    display: flex;
    max-width: 70%;

    &--self {
      align-self: flex-end;
      justify-content: flex-end;

      .conversation__message-bubble {
        background-color: #3e55cd;
        color: white;
        border-bottom-right-radius: 4px;
      }

      .conversation__message-time {
        text-align: right;
      }
    }

    &--other {
      align-self: flex-start;

      .conversation__message-bubble {
        background-color: #f2f2f2;
        color: black;
        border-bottom-left-radius: 4px;
      }
    }

    &-avatar {
      width: 32px;
      height: 28px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 8px;
      align-self: flex-end;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-bubble {
      padding: 12px 16px;
      border-radius: 18px;
      font-size: 14px;
      line-height: 1.4;
      word-wrap: break-word;
    }

    &-time {
      font-size: 11px;
      color: #717171;
      margin-top: 4px;
      padding: 0 8px;
    }
  }

  &__input {
    padding: 16px 24px;
    border-top: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    gap: 12px;

    textarea {
      flex-grow: 1;
      border: 1px solid #d8d8d8;
      border-radius: 24px;
      padding: 12px 16px;
      resize: none;
      font-size: 14px;
      height: 44px;
      max-height: 120px;
      overflow-y: auto;

      &:focus {
        outline: none;
        border-color: #3e55cd;
      }
    }

    &-buttons {
      display: flex;
      gap: 8px;
    }

    &--disabled {
      opacity: 0.7;
      background-color: #f7f7f7;
      
      textarea {
        background-color: #ebebeb;
        cursor: not-allowed;
      }
      
      button {
        cursor: not-allowed;
      }
    }
  }

  &__send-button {
    background-color: #3e55cd;
    border: none;
    border-radius: 24px;
    color: white;
    font-weight: 600;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover:not(:disabled) {
      background-color: darken(#3e55cd, 5%);
    }

    &:disabled {
      background-color: #d8d8d8;
      cursor: default;
    }
  }

  &__ended-message {
    margin: 24px auto;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 12px;
    text-align: center;
    max-width: 80%;
    color: #717171;
    border: 1px solid #ebebeb;
    
    p {
      margin: 8px 0;
      font-size: 14px;
      line-height: 1.4;
    }
    
    &-icon {
      display: flex;
      justify-content: center;
      color: #717171;
      margin-bottom: 8px;
    }
    
    &-date {
      display: block;
      font-size: 12px;
      margin-top: 8px;
      color: #999;
    }
  }

  &__error-message {
    margin: 24px auto;
    padding: 16px;
    background-color: #fff8f8;
    border-radius: 12px;
    text-align: center;
    max-width: 80%;
    color: #e34850;
    border: 1px solid #ffcdd2;
    
    p {
      margin: 8px 0;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}

@media (max-width: 768px) {
  .conversation {
    &__message {
      max-width: 85%;
    }
  }
}