.app-content-wrap {
  height: 100%;
}

.app-content {
  height: 100%;
  overflow: hidden;
}

.feed-and-map-wrapper {
  height: 100%;

  @media (min-width: 48rem) {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
}

.custom-navbar {
  width: 100%;
  height: 68px;
  /* Adjust the height as needed */
  background-color: #ffffff;
  /* Use your desired blue shade */
  position: fixed;
  top: 0;
  z-index: 1201;
  /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  box-shadow: rgb(0 0 0 / 16%) 0 0 12px;
  border-bottom: 1px solid lightgray;

  &--messages-modal-open {
    box-shadow: none;
  }

  &--building-view-open {
    box-shadow: none;
  }

  @media (min-width: 48rem) {
    &--image-modal-open {
      z-index: unset;
    }
  }

  &__section--logo {
    display: flex;
    align-items: center;
    position: relative;
    left: 2px;
    width: 40px;

    @media (min-width: 48rem) {
      width: initial;
      order: 2;
      flex: 1;
      justify-content: center;
    }
  }

  &__section__controls {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media (min-width: 48rem) {
      order: 3;
      justify-content: flex-end;
    }
  }

  &__section__main-menu {
    margin-top: 1px;

    @media (min-width: 48rem) {
      order: 1;
      flex: 1;
    }
  }

  @media (min-width: 48rem) {
    padding: 0 36px;
    height: 76px;
  }
}

.app-content-wrapper {
  height: calc((calc(var(--vh, 1vh) * 100)) - 68px); // Adjust '120px' based on the combined height of your header and other components
  margin-top: 68px;

  @media (min-width: 48rem) {
    height: calc((calc(var(--vh, 1vh) * 100)) - 76px); // Adjust '120px' based on the combined height of your header and other components
    margin-top: 76px;
  }
}

.map-feed-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 2rem;
  background-color: #dc2f2f;
  color: #fff;
  padding: 0.875rem 1.75rem;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
  z-index: 10;

  &:hover {
    background-color: #ff5e5e;
  }

  &__map-text {
    margin-right: 8px;
    width: max-content;
  }

  &__feed-text {
    margin-right: 10px;
    width: max-content;
  }

  @media (min-width: 48rem) {
    display: none;
  }
}