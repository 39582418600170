.navbar-messages-wrapper {
  cursor: pointer;

  @media (min-width: 48rem) {
    &:hover {
      border-radius: 12px;
      background-color: #f9f9f9;
      transition: background-color 0.3s ease;
    }
  }

  @media (min-width: 48rem) {
    height: 52px;
    padding: 0 8px;
  }
}

.navbar-messages {
  display: flex;
  flex-direction: column;
  z-index: 101;
  cursor: pointer;
  font-size: 0.875rem;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  min-width: 53px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;

  @media (min-width: 48rem) {
    margin-top: 6px;
    margin-right: 0;
    min-width: 60px;
    height: 46px;
    order: 1;
  }

  /*   > svg {
    margin-bottom: 4px;
  } */

  &__text {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    /* Ensures text is centered on both lines */
    font-size: 10px;

    @media (min-width: 48rem) {
      font-size: 0.875rem;
    }

    &--logged-in {
      font-size: x-small;
      display: block;
      /* Forces text onto separate lines on mobile */

      >span {
        /* Direct child selector for added specificity */
        display: block;
        /* Forces each span onto a new line on mobile */

        @media (min-width: 48rem) {
          display: inline;
          /* Allows spans to be inline on larger screens */
          font-size: initial;
        }
      }
    }
  }
}

.messages-icon {
  position: relative;

  &__circle {
    position: absolute;
    top: -4px;
    right: -7px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: #DC2F2f;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: white;
    }
  }
}